export default {
    basics: {
        confirm: '确定',
        cancel: '取消',
        close: '关闭',
        search: '搜索',
        reset: '重置',
        add: '添加',
        edit: '编辑',
        delete: '删除',
        save: '保存',
        update: '更新',
        view: '查看',
        hide: '隐藏',
        submit: '提交',
        loading: '加载中...',
        none: '无',
    },
    title: {
        printer_control: '打印机控制',
        print: "打印控制",
        current: "当前打印文件",
        login: "登录",
        welcome: "欢迎来到 IEMAI 3D Cloud！",
        describe: '多材料兼容，为应用而生。智能控制，批量生产，柔性制造，远程监控，数据可视化。',
        register: '注册',
        contact_us: '联系我们',
        contact_title: '尊敬的用户，您好！',
        page: '页面',
        username: '用户名：{username}',
        email: '邮箱：{email}',
        file: '打印文件管理',
        printer: '打印机管理',
        select: '选择打印机',
        state: '状态',
        printer_name: '名称：{printer_name}',
        delete_file: '删除后不可恢复！',
        history: '历史打印记录',
        add_printer: '添加打印机',
        list_desc: "打印机：{printerId}",
        configurator: "设置中心",
        see_our: '请参阅我们的仪表板选项。',
        language: '语言',
        feedback: '反馈',
        about: '关于我们',
        sidebar_color: '侧边栏颜色',
        server_expiration: '未激活'
    },
    label: {
        delete_file: '确定要删除文件{filename}吗？',
        username: '用户名',
        nickname: '昵称',
        pla_nickname: '昵称(允许为空)',
        password: '密码',
        remember: '记住密码',
        re_password: '修改密码',
        email: '邮箱',
        pla_email: '邮箱(允许为空)',
        code: '验证码',
        new_password: '新密码',
        con_password: '确认密码',
        select_user: '选择用户',
        add_user: '新增用户',
        load_user: '加载用户',
        active: '激活',
        contact_content: '感谢您对一迈云平台的关注和使用。一迈云平台支持单用户模式免费使用，团队模式将收取费用。目前，您的账户仍未获得创建团队的权限。如您想要申请团队模式， 请通过以下方式与我们取得联系：',
        contact_email: '邮箱：sales02@iemai3d.com',
        contact_phone: '电话：13612665576（王经理）',
        contact_url: '官方网站：www.iemai3d.cn',
        contact_footer: '我们收到您的请求后，将尽快与您取得联系。',
        printer: '打印机',
        table: '数据表',
        user_page: '用户页面',
        per_center: '个人中心',
        user_man: '团队管理',
        temp: '温度',
        userinfo: '用户信息',
        basics_info: '基础信息',
        setting: '设置',
        notice: '通知设置',
        print_start: '打印开始通知',
        print_complete: '打印完成通知',
        printer_cancel: '打印取消通知',
        printer_error: '打印异常通知',
        printer_model: '打印机型号',
        printer_name: '打印机名称',
        printer_id: '打印机唯一识别码',
        delete_printer: '确定要删除此打印机吗？',
        state: '打印状态：',
        printer_file: '当前打印的文件：',
        unknown: '未知',
        print_progress: '打印进度：',
        print_speed: '打印速率：',
        flow_rate: '挤出流量：',
        fan: '1号冷却风扇：',
        fan1: '2号冷却风扇：',
        con_duration: '消耗时长',
        rem_duration: '剩余时长',
        print_progress2: "打印进度",
        ready: '待机中',
        printing: '打印中',
        suspend: '已暂停',
        complete: '已完成',
        cancel: '已取消',
        extruder_m: "打印头中区加热",
        extruder1: '1号打印头加热',
        extruder2: '2号打印头加热',
        extruder: '打印头加热',
        bed_in: '内热床加热',
        bed: '热床加热',
        filament1: '耗材箱1加热',
        filament2: '耗材箱2加热',
        filament: '耗材箱加热',
        h1: '打印头上区加热',
        h3: '打印头下区加热',
        bed_out: '外热床加热',
        chamber: '内胆加热',
        chamber_left: '左内胆加热',
        chamber_right: '右内胆加热',
        particle: '颗粒料桶加热',
        online: '在线',
        offline: '离线',
        model: '打印机型号(自动获取)',
        id: '打印机识别码',
        printer_code: '打印机校验码',
        screen: '筛选',
        read_file: '读取打印机中的文件',
        agree_protocol: '同意协议',
        protocol: '隐私协议',
        feedback: '错误反馈',
        feedback_hint: '在此处上传打印过程中遇到的任何错误',
        camera:'相机'
    },
    button: {
        logout: '退出登录',
        move_axis: "{axis}轴移动{len}",
        axis: "{axis}轴",
        to_zero: "XYZ轴回零",
        extruder: "打印头{head}",
        feed: '一键进料',
        unfeed: '一键退料',
        start: '继续打印',
        suspend: '暂停打印',
        cease: '终止打印',
        resume: '恢复打印',
        stop: '停止打印',
        batch_print: '批量打印',
        batch_temp: '批量温控',
        upload: '上传文件',
        send_code: '发送验证码',
        forgot_password: '忘记密码？',
        no_user: '没有账户？',
        register: '注册一个',
        exist_user: '已有帐户？',
        go_login: '前往登录',
        save: '保存',
        delete: '删除',
        load_auth: '加载权限列表',
        move: '移动控制',
        heat: '加热控制',
        file: '文件管理',
        video: '视频监控',
        device: '设备管理',
        delete_printer: '删除打印机',
        edit_printer: '编辑打印机',
        check_printer: '选中打印机',
        start_print: '开始打印',
    },
    table: {
        device: '加热器件',
        max: '最大温度',
        set: '设置温度',
        confirm: '确认操作',
        name: '打印机名称',
        id: '打印机ID',
        temp: '当前温度',
        target: '目标温度',
        power: '加热功率',
        filename: '文件名',
        file_time: '文件时间戳',
        file_size: '文件大小',
        file_lines: '文件行数',
        file_md5: '文件MD5',
        estimate_time: '预计时长',
        start_time: '开始时间',
        end_time: '结束时间',
        net_print_time: '净打印时长',
        slicer: '切片软件',
        filament_total: '耗材长度',
        filament_weight: '耗材重量',
        state: '状态',
        progress: '进度',
        print: '打印',
        share: '共享',
        cancel: '打印取消',
        inter: '打印中断',
        comp: '打印完成',
        model_comp: '卸模失败',
        model_fail: '卸模完成'
    },
    card: {
        free: '空闲打印机',
        fault: '故障打印机',
        work: '工作打印机',
    }
    ,
    message: {
        open: '打开',
        info: '提示',
        success: '成功',
        error: '错误',
        warn: '警告',
        upload_success: '上传成功！',
        upload_fail: '上传失败！',
        share_success: '共享文件到{printerId}已完成！',
        share_fail: '出错了！共享文件失败！',
        delete_success: "成功删除打印机{printerId}",
        upload_warning: '上传警告',
        printer_stop: "打印任务停止！",
        printer_suspend: "打印任务暂停！",
        printer_success: "打印任务完成！",
        printer_cancel: "打印任务取消！",
        move_success: '移动控制指令执行成功！',
        move_fail: '移动控制指令执行失败！',
        change_active_extruder_success: '切换打印头{extruder}成功！',
        change_active_extruder_fail: '切换打印头{extruder}失败！',
        auto_load_success: '一键进料指令执行成功！',
        auto_load_fail: '一键进料指令执行失败！',
        auto_unload_success: '一键退料指令执行成功！',
        auto_unload_fail: '一键退料指令执行失败！',
        home_success: '回零成功！',
        home_fail: '回零成功！',
        no_file: '请先选择要打印的文件！',
        file_large: '文件大小超过{size}，请重新选择！',
        file_format: '仅允许上传{type}文件，请重新选择！',
        check_printer: '打印机{printerId}已选中！',
        on_check_printer: '打印机{printerId}已取消选中！',
        no_printer: '请先选择要开始打印的打印机！',
        u_p_empty: '用户名和密码不能为空！',
        info_empty: '请输入完整信息！',
        pw_no_match: '两次输入的密码不一致！',
        no_email: '请输入邮箱！',
        email_format: '请输入正确的邮箱格式！',
        send_code_success: '验证码发送成功！180秒内有效。',
        send_code_fail: '验证码发送失败！',
        code_wait: '上一个验证码尚未过期，还有{time}秒有效。',
        user_format: '用户名只能包含字母和数字！',
        add_user: '添加用户成功！',
        disable_user: '用户被禁用后将无法登录！',
        hide_printer: '此打印机被隐藏后该用户无法访问！',
        query_password: '查询用户密码成功！',
        modify_userinfo: '修改用户信息成功！',
        delete_user: '删除用户成功！',
        query_auth: '获取权限列表成功！',
        modify_auth: '修改权限成功！',
        edit_success: '编辑成功！',
        edit_fail: '编辑失败！',
        start_print: '即将开始打印下列文件！确定要开始吗？',
        command_success: '{action}指令执行成功！',
        command_fail: '{action}指令执行失败！',
        edit_name: '打印机名称修改成功！',
        read_file: '读取文件成功！',
        delete_file: '文件 {filename} 删除成功！',
        set_temp_success: '设置{device}温度为{num}成功！`',
        set_temp_fail: '设置{device}温度为{num}失败！`',
        start_print_file: '开始打印文件：{filename}！',
        start_print_file_error: '开始打印文件 {filename} 失败！',
        select_file: '已取消选中文件{filename}！',
        unselect_file: '已选中文件{filename}！',
        start_printing: '打印任务已开始！',
        find_printer: '成功找到打印机，型号：{model}',
        add_printer: '添加打印机成功！',
        warn_add_printer: '关闭此权限后用户无法向团队中添加新打印机！',
        name_too_long: '打印机名称长度不能超过255个字符！',
        not_login: '你还未登录，请先登录！',
        activation_successful: '激活成功',
        login_failed: '验证失败，登录信息有误！',
        feedback_success: '文件 {filename} 上传成功。',
        feedback_failed: '文件 {filename} 上传失败。',
        '403': '权限错误',
        '4002': '权限错误',
        '4004': '密码错误',
        '4005': '用户名或邮箱不可用',
        '4006': '用户处于禁用状态',
        '4007': '邮箱已存在',
        '4008': "验证码错误",
        '4009': "验证码还未过期",
        '4010': "两次密码输入不相同",
        '4011': "邮箱不存在",
        '5001': "未找到打印机，请检查打印机识别码是否正确",
        '5002': "打印机已经被占用，请检查打印机是否处于正常状态",
        '5003': "打印机验证码错误",
        '6001': "用户或密码不能为空",
        '6004': "用户名已经存在",
        '6005': "用户名不存在",
        '6002': "你尚未开通团队访问权限，请联系管理员",
        '7001': '激活码错误',
        '9999': "参数错误",
    },
    permission: {
        m: "对打印机{printer_id}的移动操作权限不足！",  // 移动 move
        p: "对打印机{printer_id}的打印操作权限不足！",  // 打印 print
        h: "对打印机{printer_id}的加热操作权限不足！",  // 加热 heat
        f: "对打印机{printer_id}的文件管理权限不足！",  // 文件 file
        v: "对打印机{printer_id}的视频监控权限不足！",  // 视频 video
        e: "对打印机{printer_id}的设备管理权限不足！",   // 打印机 equipment
        a: "你没有添加打印机的权限！",
        o: "服务器未激活或激活码已经过期！请输入新的激活码"
    },
};