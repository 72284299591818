import Vue from 'vue';
import VueI18n from 'vue-i18n';
import cn from './cn';
import en from './en';

Vue.use(VueI18n);

const messages = {
    en,
    cn
};

const locale = (navigator.language === 'zh-CN') ? 'cn' : 'en'; // 设置默认语言

const i18n = new VueI18n({
    locale, // set locale
    messages, // set locale messages
});

export default i18n;