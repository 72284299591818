import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
    {
        // will match everything
        path: '*',
        component: () => import('../views/404.vue'),
    },
    {
        path: '/',
        name: 'Home',
        redirect: '/sign-in/0',
        meta: {keepAlive: true}
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        layout: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        meta: {keepAlive: true}
    },
    {
        path: '/tables',
        name: 'Tables',
        layout: "dashboard",
        component: () => import('../views/Tables.vue'),
        meta: {keepAlive: true}
    },
    {
        path: '/Profile',
        name: 'Profile',
        layout: "dashboard",
        meta: {
            layoutClass: 'layout-profile',
            keepAlive: true
        },
        component: () => import('../views/Profile.vue'),
    },
    {
        path: '/user-management',
        name: 'UserManagement',
        layout: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/UserManagement.vue'),
        meta: {keepAlive: true}
    },
    {
        path: '/sign-in/:data',
        name: 'Sign-In',
        component: () => import('../views/LoginForm.vue'),
        meta: {keepAlive: false}
    },
    {
        path: '/sign-up',
        name: 'Sign-Up',
        component: () => import('../views/RegisterForm.vue'),
        meta: {keepAlive: false}
    },
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
    }
    return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        }
    }
})

export default router
