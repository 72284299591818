export default {
    basics: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        close: 'Close',
        search: 'Search',
        reset: 'Reset',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        save: 'Save',
        update: 'Update',
        view: 'View:',
        hide: 'Hide',
        submit: 'Submit',
        loading: 'Loading...',
        none: 'None',
    },
    title: {
        printer_control: 'Printer Control',
        print: "Print Control",
        current: "Current File",
        login: " Login",
        welcome: "Welcome To IEMAI 3D Cloud!",
        describe: 'Print More Materials, All For Applications. Intelligent Control, Batch Prodution, Flexible Manufacturing, Cloud Remote OTA, Data Visualization.',
        register: 'Register',
        contact_us: 'Contact Us',
        contact_title: 'Hello, Valued User!',
        page: 'Page',
        username: 'Username:{username}',
        email: 'Email:{email}',
        file: 'Print File Management',
        printer: 'Printer Management',
        select: 'Select Printer',
        state: 'Status',
        printer_name: 'Printer Name:{printer_name}',
        delete_file: 'Unrecoverable After Deletion!',
        history: 'Print Log',
        add_printer: 'Add Printer',
        list_desc: "Printer:{printerId}",
        configurator: "Configurator",
        see_our: 'Please Refer To Our Dashboard Options.',
        language: 'Language',
        feedback: 'Feedback',
        about: 'About Us',
        sidebar_color: 'Sidebar Color',
        server_expiration: 'Inactivated'
    },
    label: {
        delete_file: 'Are You Sure To Delete This File{filename}?',
        username: 'Username',
        nickname: 'Nickname',
        pla_nickname: 'Nickname(Allowed To Be Empty)',
        password: 'Password',
        remember: 'Remember Password',
        re_password: 'Edit Password',
        email: 'Email',
        pla_email: 'Email(Allowed To Be Empty)',
        code: 'Code',
        new_password: 'New Password',
        con_password: 'Reconfirm Password',
        select_user: 'Select User',
        add_user: 'Add User',
        load_user: 'Load User',
        active: 'Activated',
        contact_content: 'Thank You For Your Attention And Use IEMAI Cloud Platform. IEMAI Cloud Platform Supports Free Use In Single User Mode, And Team Mode Will Charge.Currently, Your Account Has Not Yet Obtained Authority To Create Team Accounts. If You Insist To Apply For Team Mode, Please Contact Us Through Following Methods: ',
        contact_email: 'Email：sales02@iemai3d.com',
        contact_phone: 'Phone：13612665576（Manager Wang）',
        contact_url: 'URL：www.iemai3d.cn',
        contact_footer: 'We Will Contact You As Soon As We Receive Your Request.',
        printer: 'Printer',
        table: 'Data Table',
        user_page: 'User Page',
        per_center: 'User Center',
        user_man: 'Team Management',
        temp: 'Temp',
        userinfo: 'User Info',
        basics_info: 'Basic Info',
        setting: 'Settings',
        notice: 'Settings Notification',
        print_start: 'Print Start Notification',
        print_complete: 'Print Completion Notification',
        printer_cancel: 'Print Cancellation Notification',
        printer_error: 'Print Error Notification',
        printer_model: 'Printer Model',
        printer_name: 'Printer Name',
        printer_id: 'Printer ID',
        delete_printer: 'Are You Sure To Delete This Printer?',
        state: 'Status',
        printer_file: 'File:',
        unknown: 'Unknown',
        print_progress: 'Progress:',
        print_progress2: 'Progress',
        print_speed: 'Speed:',
        flow_rate: 'Flow Rate:',
        fan: 'Fan 1:',
        fan1: 'Fan 2:',
        con_duration: 'Used Time',
        rem_duration: 'Time Left',
        ready: 'In Standby Mode',
        printing: 'In Printing Mode',
        suspend: 'Paused',
        complete: 'Completed',
        cancel: 'Cancelled',
        extruder_m: "Extruder Middle Zone Heating",
        extruder1: 'Nozzle Heating 1',
        extruder2: 'Nozzle Heating 2',
        extruder: 'Nozzle Heating',
        bed_in: 'Inner Hot Bed Heating',
        bed: 'Hot Bed Heating',
        filament1: 'Filament Box 1 Heating',
        filament2: 'Filament Box 2 Heating',
        filament: 'Filament Box Heating',
        h1: 'Extruder Upper Zone Heating',
        h3: 'Extruder Lower Zone Heating',
        bed_out: 'Outer Hot Bed Heating',
        chamber: 'Chamber Heating',
        chamber_left: 'Left Chamber Heating',
        chamber_right: 'Right Chamber Heating',
        particle: 'Pellet Barrel Heating',
        online: 'Online',
        offline: 'Offline',
        model: 'Printer Model(Auto Retrieve)',
        id: 'Printer ID',
        printer_code: 'Printer Verification Code',
        screen: 'Screening',
        read_file: 'Read files from the printer',
        agree_protocol: 'I agree to the protocol',
        protocol: 'Privacy Policy',
        feedback: 'Error feedback',
        // feedback_hint: 'Upload the error image here',
        feedback_hint: 'Upload any errors encountered during the printing process here',
        camera: 'Camera'
    },
    button: {
        logout: 'Logout',
        move_axis: "{axis}Axis Move{len}",
        axis: "{axis}Axis",
        to_zero: "XYZ Axis Homing",
        extruder: "Extruder{head}",
        feed: '1step Load',
        unfeed: '1step Unload',
        start: 'Continue',
        suspend: 'Pause',
        cease: 'Terminate Printing',
        resume: 'Resume Printing',
        stop: 'Stop',
        batch_print: 'Batch Print',
        batch_temp: 'Batch Temp',
        upload: 'Upload',
        send_code: 'Send Code',
        forgot_password: 'Forgot Password?',
        no_user: 'No Account?',
        register: 'Sign Up An Account',
        exist_user: 'Have Account?',
        go_login: 'Go Login',
        save: 'Save',
        delete: 'Delete',
        load_auth: 'Load Authority List',
        move: 'Motion Control',
        heat: 'Heating Control',
        file: 'File Management',
        video: 'Video Monitoring',
        device: 'Device Management',
        delete_printer: 'Delete Printer',
        edit_printer: 'Edit Printer',
        check_printer: 'Selected Printer',
        start_print: 'Start Printing',
    },
    table: {
        device: 'Heating Device',
        max: 'Max Temperature',
        set: 'Temperature Settings',
        confirm: 'Confirm Operation',
        name: 'Printer Name',
        id: 'Printer ID',
        temp: 'Current Temperature',
        target: 'Set Temperature',
        power: 'Heating Power',
        filename: 'File Name',
        file_time: 'File Timestamps',
        file_size: 'File Size',
        file_lines: 'File Line',
        file_md5: 'File MD5',
        estimate_time: 'Estimate Time',
        start_time: 'Start Time',
        end_time: 'End Time',
        net_print_time: 'Net Printing Time',
        slicer: 'Slicing Software',
        filament_total: 'Filament Total Length',
        filament_weight: 'Filament Weight',
        state: 'Status',
        progress: 'Progress',
        print: 'Printing',
        share: 'Share',
        cancel: 'Cancel',
        inter: 'Interruption',
        comp: 'Completed',
        model_comp: 'Completed',
        model_fail: 'Failed',
    },
    card: {
        free: 'Free Printer',
        fault: 'Error Printer',
        work: 'Work Printer',
    }
    ,
    message: {
        open: 'Open',
        info: 'Prompt',
        success: 'Success',
        error: 'Error',
        warn: 'Warning',
        upload_success: 'Upload Success!',
        upload_fail: 'Upload Fail!',
        share_success: 'Share File To{printerId}Completed！',
        share_fail: 'Error! File Sharing Failed!',
        delete_success: "Successfully Deleted Printer{printerId}",
        upload_warning: 'Upload Warning',
        printer_stop: "Printing Task Stopped!",
        printer_suspend: "Printing Task Suspended!",
        printer_success: "Printing Task Completed!",
        printer_cancel: "Printing Task Cancelled!",
        move_success: 'Motion Control Command Executed Successfully!',
        move_fail: 'Motion Control Command Execution Failed',
        change_active_extruder_success: 'Switching Nozzle Successful!',
        change_active_extruder_fail: 'Switching Nozzle Failed!',
        auto_load_success: 'One-Click Feed Command Executed Successfully!',
        auto_load_fail: 'One-Click Feed Command Execution Failed!',
        auto_unload_success: 'One-Click Unfeed Command Executed Successfully!',
        auto_unload_fail: 'One-Click Unfeed Command Execution Failed!',
        home_success: 'Homing Success!',
        home_fail: 'Homing Fail!',
        no_file: 'Please Select File To Print!',
        file_large: 'The File Size Exceeds {size}, Please Select Again!',
        file_format: 'Only{type}Files Are Allowed To Be Uploaded, Please Select Again!',
        check_printer: 'Printer{printerId}Is Selected！',
        on_check_printer: 'Printer{printerId}Has Been Deselected!',
        no_printer: 'Please Select Printer To Start Printing!',
        u_p_empty: 'User Name And Password Cannot Be Empty!',
        info_empty: 'Please Enter Full Info!',
        pw_no_match: 'The Passwords Entered Twice Are Inconsistent!',
        no_email: 'Please Enter Email Address!',
        email_format: 'Please Enter Correct Email Format!',
        send_code_success: 'Verification Code Sent Successfully! Effective Within 180 Seconds.',
        send_code_fail: 'Verification Code Sending Failed!',
        code_wait: 'The Previous Verification Code Has Not Expired And Is Still Valid For{time}Seconds.',
        user_format: 'The Username Can Only Contain Letters And Numbers!',
        add_user: 'Successfully Added User!',
        disable_user: 'Users Will Not Be Able To Login After Being Disabled!',
        hide_printer: 'The User Cannot Access This Printer After It Is Hidden!',
        query_password: 'User Password Query Successful!',
        modify_userinfo: 'Successfully Modified User Information!',
        delete_user: 'User Deletion Successful!',
        query_auth: 'Successfully Obtained Authority List!',
        modify_auth: 'Successfully Modified Authority!',
        edit_success: 'Edit Successful!',
        edit_fail: 'Editing Failed!',
        start_print: 'We Are About To Start Printing The Following Files! Are You Sure to Start?',
        command_success: '{action} Command Execution Successful!',
        command_fail: '{action} Command Execution Failed！',
        edit_name: 'Printer Name Has Been Successfully Modified!',
        read_file: 'Read File Successful!',
        delete_file: 'File {filename} Deleted Successfully！',
        set_temp_success: 'Set {device} Temperature To {num} Successful!',
        set_temp_fail: 'Set {device} Temperature to {num} Failed!',
        start_print_file: 'Start Print File:{filename}!',
        start_print_file_error: 'Failed To Start Print File {filename}!',
        select_file: 'Selected File {filename}!',
        unselect_file: 'File {filename} Unselected!',
        start_printing: 'Printing Task Has Started!',
        find_printer: 'Successfully Found Printer, Model:{model}',
        add_printer: 'Successfully Added Printer!',
        warn_add_printer: 'Users cannot add new printers to the team after this permission is turned off!',
        name_too_long: 'The Printer Name Cannot Exceed 255 Characters.',
        not_login: 'You are not logged in yet, please log in first!',
        activation_successful: 'Activation successful',
        login_failed: 'Verification failed, login information is incorrect!',
        feedback_success: '{filename} file uploaded successfully.',
        feedback_failed: '{filename} file upload failed.',
        error_in_type: 'You can only upload JPG/PNG file!',
        error_in_size: 'Image must smaller than 10MB!',
        '403': 'Authority Error',
        '4002': 'Authority Error',
        '4004': 'Password Error',
        '4005': 'User Name Or Email Unavailable',
        '4006': 'The User Is In A Disabled State',
        '4007': 'Email Already Exists',
        '4008': "Verification Code Error",
        '4009': "Verification Code Has Not Expired Yet",
        '4010': "Two Different Password Inputs",
        '4011': "Email Does Not Exist",
        '5001': "Unable To Find Printer, Please Check If the Printer Verification Code Is Correct",
        '5002': "Printer Is Already In Use, Please Check If the Printer Is In A Normal State",
        '5003': "Printer Verification Code Error",
        '6001': "User Or Password Cannot Be Empty",
        '6004': "Username Already Exists",
        '6005': "Username Does Not Exist",
        '6002': "You Have Not Yet Opened Team Access Authorities, Please Contact Administrator",
        '7001': 'Activation code error',
        '9999': "Parameter Error",
    },
    permission: {
        m: "Insufficient permission to move printer {printer_id}!",  // 移动 move
        p: "Insufficient printing operation permissions for printer {printer_id}!",  // 打印 print
        h: "Insufficient heating operation permission for printer {printer_id}!",  // 加热 heat
        f: "Insufficient file management permissions for printer {printer_id}!",  // 文件 file
        v: "Insufficient video surveillance permissions for printer {printer_id}!",  // 视频 video
        e: "Insufficient device management permissions for printer {printer_id}!",   // 打印机 equipment
        a: "You don't have permission to add a printer!",
        o: "The server is not activated or the activation code has expired! Please enter a new activation code"
    }
};