<template>

  <!-- Layout Footer -->
  <a-layout-footer class="ant-layout-sider-dark">
    <a-row type="flex">
      <a-col :md="12" :span="24">

        <!-- Footer Copyright Notice -->
        <p class="copyright">
          © 2024, made with
          <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
                  fill="#111827"
                  fill-rule="evenodd"/>
          </svg>
          by <a href="https://www.iemai3d.com">IEMAI 3D</a> for a better web.
        </p>
        <!-- / Footer Copyright Notice -->

      </a-col>
      <a-col :md="12" :span="24" class="footer-control">

        <!-- Footer Navigation Menu -->
<!--        <a-menu mode="horizontal">-->
<!--          <a-menu-item>Creative Tim</a-menu-item>-->
<!--          <a-menu-item>About Us</a-menu-item>-->
<!--          <a-menu-item>Blog</a-menu-item>-->
<!--          <a-menu-item>License</a-menu-item>-->
<!--        </a-menu>-->
        <!-- / Footer Navigation Menu -->

      </a-col>
    </a-row>
  </a-layout-footer>
  <!-- / Layout Footer -->

</template>

<script>

export default ({
  data() {
    return {}
  },
})

</script>
