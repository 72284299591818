<!-- 
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
  <div>
    <!-- Dashboard Layout -->
    <a-layout id="layout-dashboard"
              :class="[navbarFixed ? 'navbar-fixed' : '', ! sidebarCollapsed ? 'has-sidebar' : '', layoutClass]"
              class="layout-dashboard a-layout-theme">

      <!-- Main Sidebar -->
      <DashboardSidebar
          :sidebarCollapsed="sidebarCollapsed"
          :sidebarColor="sidebarColor"
          :sidebarTheme="sidebarTheme"
          @toggleSidebar="toggleSidebar"
      ></DashboardSidebar>
      <!-- / Main Sidebar -->

      <!-- Layout Content -->
      <a-layout>
        <!-- Layout Header's Conditionally Fixed Wrapper -->
        <DashboardHeader
            :navbarFixed="navbarFixed"
            :sidebarCollapsed="sidebarCollapsed"
            @toggleSettingsDrawer="toggleSettingsDrawer"
            @toggleSidebar="toggleSidebar"
        ></DashboardHeader>
        <!-- / Layout Header's Conditionally Fixed Wrapper -->

        <!-- Page Content -->
        <a-layout-content>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </a-layout-content>
        <!-- / Page Content -->

        <!-- Layout Footer -->
        <DashboardFooter></DashboardFooter>
        <!-- / Layout Footer -->


        <!-- Floating Action Button For Toggling Settings Drawer -->

        <!-- / Floating Action Button For Toggling Settings Drawer -->

        <!-- Sidebar Overlay -->
        <div v-show="! sidebarCollapsed" class="sidebar-overlay" @click="sidebarCollapsed = true"></div>
        <!-- / Sidebar Overlay -->

      </a-layout>
      <!-- / Layout Content -->

      <!-- Settings Drawer -->
      <DashboardSettingsDrawer
          :navbarFixed="navbarFixed"
          :showSettingsDrawer="showSettingsDrawer"
          :sidebarTheme="sidebarTheme"
          @toggleNavbarPosition="toggleNavbarPosition"
          @toggleSettingsDrawer="toggleSettingsDrawer"
          @updateSidebarColor="updateSidebarColor"
          @updateSidebarTheme="updateSidebarTheme"
      ></DashboardSettingsDrawer>
      <!-- / Settings Drawer -->

    </a-layout>
    <!-- / Dashboard Layout -->

  </div>
</template>

<script>

import DashboardSidebar from '../components/Sidebars/DashboardSidebar';
import DashboardHeader from '../components/Headers/DashboardHeader';
import DashboardFooter from '../components/Footers/DashboardFooter';
import DashboardSettingsDrawer from '../components/Sidebars/DashboardSettingsDrawer';

export default {
  components: {
    DashboardSidebar,
    DashboardHeader,
    DashboardFooter,
    DashboardSettingsDrawer,
  },
  data() {
    return {
      // Sidebar collapsed status.
      sidebarCollapsed: false,

      // Main sidebar color.
      sidebarColor: "primary",

      // Main sidebar theme : light, white, dark.
      sidebarTheme: "dark",

      // Header fixed status.
      navbarFixed: false,

      // Settings drawer visiblility status.
      showSettingsDrawer: false,
      currentRouteName: this.$route.name,
    }
  },
  methods: {
    toggleSidebar(value) {
      this.sidebarCollapsed = value;
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass;
    },
  },
  watch: {},
  beforeRouteUpdate(to, from, next) {
    this.currentRouteName = to.name;
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.currentRouteName = to.name;
    next();
  },
  mounted() {
  },
  beforeDestroy() {
  },
}

</script>

<style>
.a-layout-theme {
  background-color: rgba(0, 21, 41, 0.9);
}
</style>