/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import 'boxicons/css/boxicons.min.css';
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import router from './router'
// import './plugins/click-away'
import './scss/app.scss';
// 0、引入通信库axios
import axios from 'axios';
import {io} from "socket.io-client";
import store from './store';
import {Carousel, CarouselItem} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './i18n';

Vue.prototype.$socket = io("cloud.iemai3d.com", {autoConnect: false});  //pro
// Vue.prototype.$socket = io("192.168.31.254:3001", {autoConnect: false});  //dev
// Vue.prototype.$socket = io("192.168.31.86:80", {autoConnect: false});  //local

Vue.prototype.$axios = axios;

// 配置全局域名
axios.defaults.baseURL = "https://cloud.iemai3d.com/api";  //pro
// axios.defaults.baseURL = "/x";  //dev
// axios.defaults.baseURL = "http://192.168.31.86/api/";  //local

Vue.use(Antd);
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);


// 定义全局权限验证函数
Vue.prototype.$checkBit = function (num, auth) {
    const PermissionTable = {
        // 权限位：1 2 4 8 16 32
        "m": 0,  // 移动 move
        "p": 1,  // 打印 print
        "h": 2,  // 加热 heat
        "f": 3,  // 文件 file
        "v": 4,  // 视频 video
        "e": 5   // 打印机 equipment
    };
    const bit = PermissionTable[auth];
    if (bit === undefined) {
        return false;
    }
    return ((num & (1 << bit)) === (1 << bit));
}


Vue.prototype.$codeWithMsg = function (code) {

    const codeTable = {
        0: "成功",
        403: "权限错误",
        4002: "权限错误",
        4004: "密码错误",
        4005: "用户名或邮箱不可用",
        4006: "用户处于禁用状态",
        4007: "邮箱已存在",
        4008: "验证码错误",
        4009: "验证码还未过期",
        4010: "两次密码输入不相同",
        4011: "邮箱不存在",
        5001: "未找到打印机，请检查打印机识别码是否正确",
        5002: "打印机已经被占用，请检查打印机是否处于正常状态",
        6001: "用户或密码不能为空",
        6004: "用户名已经存在",
        6005: "用户名不存在",
        6002: "你尚未开通团队访问权限，请联系管理员",
        5003: "打印机验证码错误",
        9999: "参数错误",
    }
    return codeTable[code] ? codeTable[code] : "未知错误";
}


new Vue({
    i18n,
    store,
    router,
    render: h => h(App)
}).$mount('#app')