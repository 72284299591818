// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function findIndexById(array, id) {
    return array.findIndex(obj => obj.id === id);
}

export default new Vuex.Store({
    state: {
        userInfo: {
            username: '',
            email: '',
            avatar: '',
            nickname: '',
            temp_id: '',

        },
        lang: 'cn',
        onlinePrinter: [],
        selectedPrintFiles: [],
        temps: {},
        PrintStartNotice: true,
        PrintCompleteNotice: true,
        PrintCancelNotice: true,
    },
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo.username = payload.username ? payload.username : state.userInfo.username;
            state.userInfo.email = payload.email ? payload.email : state.userInfo.email;
            state.userInfo.avatar = payload.avatar ? payload.avatar : state.userInfo.avatar;
            state.userInfo.nickname = payload.nickname ? payload.nickname : state.userInfo.nickname;
            state.userInfo.temp_id = payload.temp_id ? payload.temp_id : state.userInfo.temp_id;
        },
        setOnlinePrinter(state, payload) {
            state.onlinePrinter = payload
        },
        setSelectedPrintFile(state, payload) {
            let index = state.selectedPrintFiles.findIndex(item => JSON.stringify(item) === JSON.stringify(payload))
            if (index !== -1) {
                state.selectedPrintFiles.splice(index, 1)
            } else {
                state.selectedPrintFiles.push(payload)
            }
        },
        setTemps(state, payload) {
            let model = Object.keys(payload)[0]
            // 判断是否已经存在
            if (state.temps[model] === undefined) {
                state.temps[model] = payload[model]
                for (let i = 0; i < state.temps[model].length; i++) {
                    state.temps[model][i].children = [state.temps[model][i].children]
                }
            } else {
                for (let i = 0; i < payload[model].length; i++) {
                    let index = state.temps[model].findIndex(item => item.name === payload[model][i].name)
                    if (index === -1) {
                        state.temps[model].push(payload[model][i])
                    } else {
                        let children_index = state.temps[model][index].children.findIndex(item => item.id === payload[model][i].children.id)
                        if (children_index === -1) {
                            state.temps[model][index].children.push(payload[model][i].children)
                        } else {
                            state.temps[model][index].children[children_index].temperature = payload[model][i].children.temperature
                            state.temps[model][index].children[children_index].target = payload[model][i].children.target
                            state.temps[model][index].children[children_index].power = payload[model][i].children.power
                        }
                    }
                }
            }
        },
        setLogout(state, payload) {
            state.userInfo = {
                username: '',
                email: ''
            };
            state.onlinePrinter = [];
            state.selectedPrintFiles = [];
            state.temps = {}
        },
        setPrintStartNotice(state, payload) {
            state.PrintStartNotice = payload;
        },
        setPrintCompleteNotice(state, payload) {
            state.PrintCompleteNotice = payload
        },
        setPrintCancelNotice(state, payload) {
            state.PrintCancelNotice = payload
        },
        setLang(state, payload) {
            state.lang = payload
        },
    },
    actions: {
        updateUserInfo({commit}, payload) {
            commit('setUserInfo', payload);
        },
        updateOnlinePrinter({commit}, payload) {
            const processedTreeData = payload.map((node, index) => {
                return {
                    ...node,
                    // key: index.toString(),
                    value: index.toString(),
                    children: node.children.map((child, childIndex) => {
                        return {
                            ...child,
                            value: `${index}-${childIndex}`,
                            // value: child.title
                        }
                    })
                }
            })
            commit('setOnlinePrinter', processedTreeData);
        },
        updateSelectedPrintFile({commit}, payload) {
            commit('setSelectedPrintFile', payload)
        },
        updateTemps({commit}, payload) {
            commit('setTemps', payload)
        },
        logout({commit}) {
            commit('setLogout', null)
        },
        updatePrintCancelNotice({commit}, payload) {
            commit('setPrintCancelNotice', payload)
        },
        updatePrintStartNotice({commit}, payload) {
            commit('setPrintStartNotice', payload)
        },
        updatePrintCompleteNotice({commit}, payload) {
            commit('setPrintCompleteNotice', payload)
        },
        updateLang({commit}, payload) {
            commit('setLang', payload)
        }
    },
    getters: {
        getUserInfo: state => {
            return state.userInfo;
        },
        getOnlinePrinter: state => {
            return state.onlinePrinter
        },
        getSelectedPrintFile: state => {
            return state.selectedPrintFiles
        },
        getTemps: state => {
            return state.temps
        },
        getPrintCancelNotice: state => {
            return state.PrintCancelNotice
        },
        getPrintStartNotice: state => {
            return state.PrintStartNotice
        },
        getPrintCompleteNotice: state => {
            return state.PrintCompleteNotice
        },
        getLang: state => {
            return state.lang
        }
    }
});